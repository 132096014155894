import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["counter"]
  static values = { count: Number }

  handleDelete(event) {
    this.countValue -= 1
    this.counterTarget.textContent = "(" + this.countValue + ")"

    const docsTab = document.querySelector('.js-documents-tab')
    const totalCount = parseInt(docsTab.dataset.count) - 1
    docsTab.dataset.count = totalCount
    docsTab.textContent = "Docs (" + totalCount + ")"

    event.target.closest("tr").remove()
    this.dispatch("fileDeleted")

    if (this.countValue === 0) this.element.remove()
  }
}
