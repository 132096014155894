import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["downIcon", "upIcon"]
  static values = {collapsed: Boolean}

  toggleIcon() {
    if (this.collapsedValue) {
      this.showIcon()
    } else {
      this.downIconTarget.classList.remove('hidden')
      this.upIconTarget.classList.add('hidden')
      this.collapsedValue = true
    }
  }

  showIcon() {
    this.downIconTarget.classList.add('hidden')
    this.upIconTarget.classList.remove('hidden')

    this.collapsedValue = false
  }
}
